import React from "react";
import "./Button.css";

export default function Button({ text, className, href, newTab, disabled }) {
  return (
    <div className={className}>
      {disabled ? (
        <a class="main-button disabled">{text}</a>
      ) : (
        <a class="main-button" href={href} target={newTab && "_blank"}>
          {text}
        </a>
      )}
    </div>
  );
}
