/* Change this file to get your personal Portfolio */

// Your Summary And Greeting Section

import emoji from "react-easy-emoji";

const greeting = {
  /* Your Summary And Greeting Section */
  username: "Martin Ganly",
  title: "Hi, I'm Martin",
  subTitle: emoji(
    "A passionate Software Developer with a wealth of experience building Web and Mobile applications using JavaScript / Reactjs / Nodejs / Java / SpringBoot and many other cool libraries and frameworks."
  ),
  resumeLink: "https://drive.google.com/file/d/15p52rCBUPkd02t7_uUfeIZGiwso3J-KI/view?usp=sharing",
};

// Your Social Media Link

const socialMediaLinks = {
  linkedin: "https://www.linkedin.com/in/martin-ganly-76679a136/",
  facebook: "https://www.facebook.com/MartinGanly",
  gmail: "martin@martinganly.co.uk",
  gitlab: "https://gitlab.com/",
  github: "https://github.com/",
  // Instagram and Twitter are also supported in the links!
};

// Your Skills Section

const skillsSection = {
  title: "What i do",
  subTitle:
    "Full stack development with a passion for creating elegant APIs alongside highly responsive interactive user interfaces.",
  skills: [
    emoji("⚡ Develop highly interactive Front End / User Interfaces for your web and mobile applications"),
    emoji("⚡ Develop efficient Back End API services to serve your front end applications."),
    emoji("⚡ Design and manage database systems in both SQL and NoSQL formats."),
    emoji("⚡ Adhere to Agile development processes and best practices."),
  ],

  /* Make Sure You include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5",
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt",
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass",
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react",
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node",
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm",
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database",
    },
    {
      skillName: "nosql-database",
      fontAwesomeClassname: "fas fa-database",
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws",
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python",
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker",
    },
    {
      skillName: "java",
      fontAwesomeClassname: "fab fa-java",
    },
    {
      skillName: "spring-boot",
      fontAwesomeClassname: "fas fa-leaf",
    },
    {
      skillName: "git",
      fontAwesomeClassname: "fab fa-git",
    },
    {
      skillName: "linux",
      fontAwesomeClassname: "fab fa-linux",
    },
  ],
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "UX Design", //Insert stack or technology you have experience in
      progressPercentage: "50%", //Insert relative proficiency in percentage
    },
    {
      Stack: "UI Development",
      progressPercentage: "90%",
    },
    {
      Stack: "Back End Development",
      progressPercentage: "80%",
    },
    {
      Stack: "Version Control",
      progressPercentage: "70%",
    },
    {
      Stack: "Continous Integration",
      progressPercentage: "70%",
    },
  ],
};

// Your top 3 work experiences

const workExperiences = {
  viewExperiences: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Director",
      company: "Catalyst Applications",
      companylogo: require("./assets/images/CAlogo.png"),
      date: "July 2020 – Present",
      desc:
        "Principle developer in the creation of B2B software solutions, responsible for overseeing feature development, adhering and ensuring the high quality standards of the development team's code.",
      descBullets: [
        "Managing front end React development enforcing MVC design practices with ES6 format.",
        "Proposing architectural design and feature solutions from requirements.",
        "Managing code reviews to ensure high quality efficient code.",
        "Feature development utilizing test driven development practices and continuous integration.",
      ],
    },
    {
      role: "Systems Engineer",
      company: "Verint Systems",
      companylogo: require("./assets/images/verintsystems.jpeg"),
      date: "March 2021 - Present",
      desc:
        "Working alongside a close knit team on Verint's Engagement Management product to produce highly scalable and extensible features and solutions.",
      descBullets: [
        "Contributing to the development and continous enhancement of Java Spring Boot microservices.",
        "Working with propriety internal tools to maintain the product platform.",
        "Producing high quality and high coverage unit, integration and acceptance tests.",
        "Involved in code reviews to ensure high quality, well tested and efficient code.",
      ],
    },
    {
      role: "Software Engineer",
      company: "Agile CIC",
      companylogo: require("./assets/images/agileciclogo.png"),
      date: "Aug 2018 – Jun 2019",
      desc:
        "Working as part of a close knit team with charity Agile CIC to develop an analytical management tool which would be integrated into an existing system.",
      descBullets: [
        "Design and configuration of SQL database.",
        "Front End UI design.",
        "Front end feature development.",
        "Back End API design and development",
        "Management of continous integration configuration and pipelines.",
      ],
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "false", // Set true or false to show Contact profile using Github, defaults to false
};

// Some Big Projects You have worked with your company

const bigProjects = {
  title: "Other Projects",
  subtitle: "Additional projects I have created or been involved with.",
  projects: [
    {
      image: require("./assets/images/saayaHealthLogo.webp"),
      link: "http://saayahealth.com/",
    },
    {
      image: require("./assets/images/nextuLogo.webp"),
      link: "http://nextu.se/",
    },
  ],
};

// Your Achievement Section Include Your Certification Talks and More

const achievementSection = {
  title: emoji("Achievements 🏆 "),
  subtitle: "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done!",

  achivementsCards: [
    {
      title: "Dundee University DUCS Hackathon 2020",
      subtitle:
        "Secured 1st place through development of an application which models Covid-19 data within an interactive 3D globe.",
      image: require("./assets/images/dundeeunilogo.png"),
      footerLink: [],
    },
    {
      title: "University Of Glasgow GUTS Hackathon 2019",
      subtitle:
        "1st place award for development of a React Native based prototype mobile AR application that allows people from all over the world to contribute to an artistic canvas at tourist attractions.",
      image: require("./assets/images/glasgowunilogo.png"),
      footerLink: [],
    },

    {
      title: "University Of Glasgow GUTS Hackathon 2018",
      subtitle:
        "3rd place achieved through development of a top down fast paced action game which depicted an apocalyptic event where the protagonist had to save their friends and themselves, written in C#",
      image: require("./assets/images/glasgowunilogo.png"),
      footerLink: [],
    },
  ],
};

// Blogs Section

const blogSection = {
  title: "Other Projects",
  subtitle: "Additional projects I have created or been involved with.",

  blogs: [
    {
      url: "https://www.sammusicservice.com",
      title: "Sam Music Service - mySam",
      description:
        "An online platform which provides music tutors and students simple and easy access to online lesson video conferencing, lesson recordings, lesson reports and practice folders",
    },
    {
      url: "https://cataclysm.world",
      title: "Cataclysm World",
      description:
        "Covid-19 Data Modelled Using An Interactive 3D Globe which allows you to see how countries are affected by Covid-19 and the migigation levels governments have put in place. Users can move along the timeline to see how Covid-19 progresses.",
    },
    {
      url: "https://www.a1mowers.co.uk",
      title: "A1 Mowers",
      description:
        "A single page small business website built in React with a custom sales page. Utilizes auto deployment and email triggered scripts to minimise costs by avoiding the need for a back end.",
    },
    {
      url: "https://writearoundscotland.com/",
      title: "Write Around Scotland",
      description:
        "A blog based website written with a PHP backend alongside a vanilla javascript and jQuery front end.",
    },
  ],
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji("I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/",
    },
  ],
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo",
  ],
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle: "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "",
  email_address: "martin@martinganly.co.uk",
};

//Twitter Section

const twitterDetails = {
  userName: "", //Replace "twitter" with your twitter username without @
};
export {
  greeting,
  socialMediaLinks,
  skillsSection,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
};
